import React from 'react'
import { Link } from 'gatsby'
import aboutImage from '../../assets/images/about/about-img5.png'
import starIcon from '../../assets/images/star-icon.png'
import icon4 from '../../assets/images/icons/icon4.png'
import icon5 from '../../assets/images/icons/icon5.png'
import icon6 from '../../assets/images/icons/icon6.png'
import icon7 from '../../assets/images/icons/icon7.png'
import shape1 from '../../assets/images/shape/circle-shape1.png'

const Retreat3DaysContent = () => {
    return (
        <section className="about-area ptb-10">
            <div className="container-fluid">
                <div className="row align-items-center">
                    <div className="col-lg-6 col-md-12">
                        <div className="about-image">
                            <img src={aboutImage} alt="banner" />
                        </div>
                    </div>

                    <div className="col-lg-6 col-md-12">
                        <div className="about-content">
                            <div className="content">
                                <span className="sub-title">
                                    <img src={starIcon} alt="banner" />
                                    3-Days Retreat
                                </span>
                                <h3>Charming and Idyllic Retreat</h3>
                                <p> Welcome to our 3-day retreat program! Our program is designed to help you relax, recharge, and reconnect with yourself.
                                    We have carefully curated a range of activities and experiences that are designed to nourish your mind, body, and spirit.</p>
                                <h3>Day 1: Arrival and Relaxation</h3>
                                <p> Upon arrival, you will be greeted by our staff and given a tour of the retreat center.
                                    You will then have some time to settle in and explore the beautiful natural surroundings.
                                    In the evening, we will gather for a welcome dinner, followed by a gentle yoga session to
                                    help you unwind and let go of any stress or tension.</p>
                                <h3>Day 2: Mindfulness and Connection</h3>
                                <p> On the second day, we will focus on mindfulness and connection.
                                    The day will begin with a guided meditation session to help you start the day feeling calm and centered.
                                    We will then have a group workshop on mindful living, where you will learn techniques for staying present
                                    and focused in your daily life. In the afternoon, we will have a group hike in the beautiful surrounding nature.
                                    The evening will conclude with a group dinner and a campfire circle, where we can connect and share our experiences.</p>
                                <h3>Day 3: Self-Discovery and Reflection</h3>
                                <p> The final day will focus on self-discovery and reflection. We will start with a gentle yoga session to help
                                    you connect with your body and mind. We will then have a group workshop on self-discovery, where you will
                                    be guided through exercises to help you identify your values, goals, and priorities. In the afternoon,
                                    we will have some free time for you to reflect on your experiences and connect with yourself.
                                    The retreat will conclude with a closing ceremony, where you will have the opportunity to share your
                                    insights and experiences with the group.</p>
                                <p>Our 3-day retreat program offers a unique and transformative experience, designed to help you relax,
                                    recharge, and reconnect with yourself. Whether you're looking to reduce stress, cultivate mindfulness,
                                    or gain clarity and direction in your life, this retreat program has something to offer. Join us for a
                                    weekend of self-discovery, connection, and growth, and return home feeling refreshed, renewed, and inspired.</p>

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="circle-shape1">
                <img src={shape1} alt="banner" />
            </div>


        </section>
    )
}

export default Retreat3DaysContent;